@use '../includes' as *;

.supporting_text {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: $g24;
  max-width: 665px;

  p {
    @include body-copy-2;
  }
}

.title {
  @include h3;
}

.toggle_button {
  position: relative;
  width: 165px; // magic number to stop the text from shifting button size

  svg {
    position: absolute;
    inset-inline-end: $g20;
  }
}

.secondary_text {
  max-height: 1000px;
  margin-block-end: $g24;
  overflow: hidden;
  transition-property: max-height;
  transition-duration: var(--duration);
  transition-timing-function: ease-in-out;

  h4 {
    @include h4;
  }

  p {
    margin-block-start: $g16;

    @include body-copy-2;
  }
}

.hidden {
  max-height: 0;
  margin-block-end: 0;
}
